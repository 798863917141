<template>
  <div>
    <div class="opt-bar flex"> 
      <div class="opt-left">     
        <el-input size="small"
            @keyup.enter.native="onQuery"
            class="searcher-input"
            placeholder="关键词"
            clearable
            @clear="onQuery"
            v-model="query.skey">
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
        <el-button  @click="onQuery" type="primary" size="mini"><i class="iconfont iconsearch"></i></el-button>
        <el-button @click="onBtnDeleteClick" type="danger" size="mini"><i class="iconfont iconclose"></i>删除</el-button>
      </div>
      <div class="opt-right">
        <el-tag>共{{list.length}}个草稿</el-tag>        
      </div>
    </div>
    <split-pane split="vertical" class="pane" :default-percent='70' :resizerThickness="5" @resize="paneResizeVertical">
      <template slot="paneL">
        <el-table :data="list" element-loading-text="Loading..."
          size="small"
          border fit highlight-current-row
          header-row-class-name="tableHeader"
          :height="tableHeight"
          :row-class-name="tableRowClass"
          row-key="draft_id"
          default-expand-all
        >     
          <el-table-column label="draft_id" width="80" align="center">
            <template slot-scope="scope">
              {{ scope.row.draft_id }}
            </template>
          </el-table-column>
          <el-table-column label="源名称" width="130" align="center">
            <template slot-scope="scope">          
              <span>{{ scope.row.source_miniprogram }}</span>          
            </template>
          </el-table-column>
          <el-table-column label="user_version" width="130" align="left">
            <template slot-scope="scope">          
              <span>{{ scope.row.user_version }}</span>          
            </template>
          </el-table-column>
          <el-table-column label="user_desc"  align="left">
            <template slot-scope="scope">
              {{ scope.row.user_desc}}
            </template>
          </el-table-column>
          <el-table-column label="create_time" width="135" align="center">
            <template slot-scope="scope">
              {{ transStamp(scope.row.create_time)}}
            </template>
          </el-table-column>
          <el-table-column label="界面截图" width="160" align="center">
            <template slot-scope="scope">
              {{ scope.row.img}}
            </template>
          </el-table-column>            
          <el-table-column label="操作" width="160" align="center">
            <template slot-scope="scope">
              <div class="row-opt">
                <el-tooltip class="item" effect="dark" content="添加到模板库" placement="bottom">
                  <icon-btn @click="handleUpload(scope.$index, scope.row)"
                  icon="iconnew"
                  type="success"
                  ></icon-btn>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="上传界面截图" placement="bottom">
                  <el-upload
                    class="upload-demo"
                    action="https://jsonplaceholder.typicode.com/posts/"
                    :on-preview="handlePreview"                                    
                    :limit="1"
                    :on-exceed="handleExceed"
                    :file-list="fileList">
                    <icon-btn @click="handleUpload(scope.$index, scope.row)"
                    icon="iconicon-test"
                    type="warning"
                    ></icon-btn>
                  </el-upload>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="上传界面截图" placement="bottom">
                  <icon-btn @click="handleDelete(scope.$index, scope.row)"
                  icon="icondel1"
                  type="error"
                  ></icon-btn>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <template slot="paneR">      
        <div class="pane-right" v-if="current">

        </div>
      </template>
    </split-pane>
    <div v-if="refreshFlag"></div>
  </div>
</template>


<script>
import splitPane from 'vue-splitpane'
import IconBtn from '../../components/btns/IconBtn.vue';
export default {
  components: {   IconBtn, splitPane },
  data(){
    return {
      tableHeight: 560,
      refreshFlag: false,
      query: {skey: ''},
      list: [],
      fileList: [],//为图片上传
      current: false,
    } //--return
  },//--data
  created(){
    this.resize();
    window.onresize = this.resize();
    this.onQuery();
  },
  methods: {
    resize() {
      var clientHeight = document.documentElement.clientHeight
      this.tableHeight = clientHeight - 140
    },
    onBtnSyncClick() {

    },
    handleUpload(idx, row) {

    },
    onBtnDeleteClick(idx, row) {

    },
    mouseLeave(row) {
      this.current = false
    },
    mouseEnter(row) {
      this.current = row;
    },
    onQuery() {
      this.post('/api/waxlib/draftList', {}, res=>{
        if (res.errcode == 0) {
          this.list = res.draft_list
          if (this.list.length> 0) {
            this.current = this.list[0]
          }
          // console.log(this.list)
        } else {
          this.$notify.error('获取列表失败~' + res.errmsg);
        }
      })
    },
    onPageSizeChange(e) {
      this.page.pageSize = e;
      this.onQueryPage(1)
    },
    tableRowClass({row, rowIndex}) {
      if (row.upid == 0) {
        return 'success-row'
      }
      if (row.stat === 0) {
        return "error-row"
      } else {
        if (rowIndex % 2 === 0) {
          return '';
        } else {
          return 'second-row'
        }
      }
    },
    transStamp(dt) {
      var sdt = dt * 1000      
      return this.util.formatDate(new Date(sdt))
    },
    paneResizeVertical(e) {

    },
    handleExceed(files, fileList) {

    },
    handlePreview(file) {

    },
  }//-methods
};
</script>

<style scoped lang="scss">
.row-opt {
  display: flex;
  max-width: 160px;  
  text-align: center;
  justify-content: space-around;
  .item {
    flex: 0 0 auto;
  }
  .upload-demo {
    display: inline;    
  }
}
</style>